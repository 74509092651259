import React from 'react'
import "../App.css"
import { useMediaQuery, useTheme } from "@mui/material"

export default function PrintableComponent({ arr }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    function changeTimeRegion(dateString) {

        const utcDate = new Date(dateString)

        const formatter = new Intl.DateTimeFormat('en-PK', {
            timeZone: 'Asia/Karachi',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        })

        let formValue = formatter.format(utcDate)

        const year = formValue.slice(6, 10)
        const month = formValue.slice(3, 5)
        const day = formValue.slice(0, 2)

        // Format the date as "YYYY-MM-DD"
        const formattedDate = `${day}-${month}-${year}`

        return formattedDate

    }

    return (
        <div id="printable-component" className="slip">
            <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                <tr>
                    <td
                        style={{ width: "533pt", border: "2px solid black" }}>
                        <p class="s1" style={{ textAlign: "center", textTransform: 'uppercase', height: 10 }}>
                            {arr.companyName}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "533pt", border: "2px solid black" }}>
                        <p class="s2" style={{ textAlign: "center", textTransform: 'uppercase', height: 10 }}>Pay slip for the month of {arr.month_name + " - " + arr.year} </p>
                    </td>
                </tr>
            </table>
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
                <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>Employee</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{isMobile ? arr.employeename.substring(0, arr.employeename.indexOf(' ')) : arr.employeename}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>Designation</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{isMobile ? arr.designationName_short : arr.designationName}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>ID</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{arr.employee_id}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>Worked</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10, minWidth: 70 }}>{arr.totalDaysWorked} Days</p>
                        </td>
                    </tr>
                </table>
                <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                    <tr >
                        <td style={{ width: "136pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left", height: 10 }}>Date</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{arr.salaryDate ? changeTimeRegion(arr.salaryDate) : ""}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "136pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left", height: 10 }}>Mode</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{arr.paymentMode}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "136pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left", height: 10 }}>Bank</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{arr.employeebank ? arr.employeebank : "-"}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "136pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left", height: 10 }}>Account</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left", height: 10 }}>{arr.bankaccount ? arr.bankaccount : "-"}</p>
                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                <tr>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}>Earning</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}>Amount</p>
                    </th>
                    <th
                        style={{ width: "4pt", border: "2px solid black", }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}><br /></p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}>Deduction</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}>Amount</p>
                    </th>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Basic Pay</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.base_salary}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Advance</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.advance ? arr.advance : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Incentive Pay</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.incentive}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Loan</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.loan ? arr.load : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Conveyance</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{+ arr.conveyance}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Absent</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.absentDeductionTotal ? Math.round(arr.absentDeductionTotal) : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Overtime</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{Math.round((arr.OT_hours * arr.OT_rate))}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Late Timing</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{Math.round(arr.lateDeductionTotal)}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Other</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.other_allowance}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left", height: 10 }}>Half Day</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{Math.round(arr.halfDayDeductionTotal)}</p>
                    </td>
                </tr>
                <tr>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left", height: 10 }}>Total</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.base_salary + arr.incentive + arr.conveyance + arr.other_allowance + Math.round((arr.OT_hours * arr.OT_rate))}</p>
                    </th>
                    <th
                        style={{ width: "4pt", border: "2px solid black", }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}><br /></p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left", height: 10 }}>Total</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{Math.round(arr.absentDeductionTotal) + Math.round(arr.lateDeductionTotal) + Math.round(arr.halfDayDeductionTotal)}</p>
                    </th>
                </tr>
                <br />
                <tr>
                    <th
                        style={{ width: "132pt" }}>
                        {/* <p class="s2" style={{ textAlign: "left", height: 10 }}>Total</p> */}
                    </th>
                    <th
                        style={{ width: "132pt" }}>
                        {/* <p class="s2" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>{arr.base_salary + arr.incentive + arr.conveyance + arr.other_allowance + Math.round((arr.OT_hours * arr.OT_rate))}</p> */}
                    </th>
                    <th
                        style={{ width: "4pt" }}>
                        <p class="s2" style={{ textAlign: "center", height: 10 }}><br /></p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left", height: 10 }}>Net Pay</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right", height: 10 }}>
                            {/* {Math.round(arr.absentDeductionTotal) + Math.round(arr.lateDeductionTotal) + Math.round(arr.halfDayDeductionTotal)} */}
                            {((Math.round((arr.OT_hours ? arr.OT_hours : 0) * Math.round(arr.OT_rate)) + Math.round(arr.totalSalary)) + (arr.conveyance + arr.incentive + arr.other_allowance)) - (arr.advance ? arr.advance : 0 + arr.loan ? arr.loan : 0 + arr.tax ? arr.tax : 0)}
                        </p>
                    </th>
                </tr>
            </table>
            <br />
            <br />
            <table>
                <tr>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                    <td style={{ width: "260pt", borderTop: "2px solid black" }}>
                        <p style={{ textAlign: "Center" }}>Authorised Signature</p>
                    </td>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                    <td style={{ width: "260pt", borderTop: "2px solid black" }}>
                        <p style={{ textAlign: "Center" }}>Employee Signature</p>
                    </td>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                </tr>
            </table>
        </div>

    )
}