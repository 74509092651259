import React, { useState, useEffect } from 'react'
import { Box, Card, Typography, TextField, Button } from '@mui/material'
import { MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import "./css/pages.css"
import axios from "axios"
import MaskedTextField from 'react-masked-mui-textfield'
import Cookies from 'js-cookie'
import toast, { Toaster } from 'react-hot-toast'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

export default function DataTable(props) {

  const { title, icon, columns, rows, openModal1, fetchEmployeeList, rules } = props

  const [newRows, setNewRows] = useState()
  const [filterRows, setfilterRows] = useState([])
  const [newRules, setRules] = useState([])
  const [editEmployee, setEditEmployee] = useState({})
  const [modalIsOpen2, setIsOpen2] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [buttonMessage, setButtonMessage] = useState('')
  const [searchBy, setSearchBy] = useState('Employee')
  const [searchByValue, setSearchByValue] = useState('')
  const [modalIsOpen3, setIsOpen3] = useState(false)
  const [fetchRules, setFetchRules] = useState([])

  function setfilterRowss(e) {
    let temp = newRows.filter((v, i) => {
      return v.employeename.toLowerCase().indexOf(e.toLowerCase()) !== -1
    })
    setfilterRows(temp)
  }

  function searchByCriteria(e) {
    setSearchByValue(e)
    if (searchBy == "Branch") {
      let temp = newRows.filter((v, i) => {
        return v.branch_name == e
      })
      if (temp.length > 0) {
        setfilterRows(temp)
      }
      else {
        setfilterRows([{}])
      }
    }
    else if (searchBy == "Designation") {
      let temp = newRows.filter((v, i) => {
        return v.designation == e
      })
      if (temp.length > 0) {
        setfilterRows(temp)
      }
      else {
        setfilterRows([{}])
      }
    }
    else if (searchBy == "Department") {
      let temp = newRows.filter((v, i) => {
        return v.departmentName == e
      })
      if (temp.length > 0) {
        setfilterRows(temp)
      }
      else {
        setfilterRows([{}])
      }
    }
    else {
      let temp = newRows.filter((v, i) => {
        return v.isActive == e
      })
      if (temp.length > 0) {
        setfilterRows(temp)
      }
      else {
        setfilterRows([{}])
      }
    }
  }

  function reset() {
    setfilterRows([])
    setSearchByValue('')
    setSearchBy("Employee")
  }

  // function changeTimeRegion(dateString) {

  //   if (dateString !== undefined) {

  //     const utcDate = new Date(dateString)

  //     const formatter = new Intl.DateTimeFormat('en-PK', {
  //       timeZone: 'Asia/Karachi',
  //       year: 'numeric',
  //       month: '2-digit',
  //       day: '2-digit',
  //       // hour: '2-digit', 
  //       // minute: '2-digit',
  //       // second: '2-digit'
  //     })

  //     let formValue = formatter.format(utcDate)

  //     const year = formValue.slice(6, 10)
  //     const month = formValue.slice(3, 5)
  //     const day = formValue.slice(0, 2)

  //     // Format the date as "YYYY-MM-DD"
  //     const formattedDate = `${year}-${month}-${day}`

  //     return formattedDate
  //   }

  // }

  function handledataDept(e) {
    departmentList.map((v, i) => {
      if (v.department_id === e.target.value) {
        setEditEmployee({ ...editEmployee, [e.target.name]: e.target.value, ait: v.inTime, lia: v.late, aot: v.outTime, eoa: v.early, halfdayin: v.halfdayin, halfdayout: v.halfdayout, workinghours: v.workinghours })
      }
    })
  }

  useEffect(() => {
    setNewRows(rows)
    setRules(rules)
    // fetchDepartmentList()
    // fetchDesignationList()
    // fetchBranchList()
  })

  useEffect(() => {
    // setNewRows(rows)
    // setRules(rules)
    fetchDepartmentList()
    fetchDesignationList()
    fetchBranchList()
  }, [])

  const fetchDepartmentList = async () => {
    try {
      const response = await axios.post('/api/fetchDepartmentList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setDepartmentList(response.data.data)
      }
      else {
        console.error('Failed to fetch department list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching department list: ', error)
    }
  }

  const fetchDesignationList = async () => {
    try {
      const response = await axios.post('/api/fetchDesignationList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setDesignationList(response.data.data)
      }
      else {
        console.error('Failed to fetch designation list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching designation list: ', error)
    }
  }

  const fetchBranchList = async () => {
    try {
      const response = await axios.post('/api/fetchBranchList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setBranchList(response.data.data)
      }
      else {
        console.error('Failed to fetch branch list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching branch list: ', error)
    }
  }

  const setup = () => {
    openModal1()
  }

  const setupRights = async (v) => {
    try {
      const response = await axios.post('/api/fetchRulesAll', {
        id: v.employee_id
      })
      if (response.data.response) {
        setFetchRules(response.data.data)
        setIsOpen3(true)
      }
      else {
        toast.error(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            minWidth: '300px',
            minHeight: "50px"
          }
        })
        // console.error('Failed to fetch branch list: ', response.data.error)
      }
    } catch (error) {
      toast.error(error, {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
      // console.error('Error fetching branch list: ', error)
    }
  }

  const closeModal2 = () => {
    setIsOpen2(false)
  }

  const closeModal3 = () => {
    setIsOpen3(false)
  }

  const setupedit = (v) => {
    setEditEmployee(v)
    setIsOpen2(true)
  }

  function handledata(e) {
    setEditEmployee({ ...editEmployee, [e.target.name]: e.target.value })
  }

  const editEmployeeRights = async (e) => {
    e.preventDefault()
    console.log(e)
    // try {
    //   setButtonMessage("Submitting")
    //   const response = await axios.post('/api/editEmployeeSubmit', {
    //     body: editEmployee
    //   })
    //   if (response.data.success) {
    //     setButtonMessage('')
    //     setEditEmployee({
    //       ...editEmployee,
    //       employeecode: "",
    //       employeecnic: "",
    //       employeename: "",
    //       employeeemail: "",
    //       guardianname: "",
    //       // roll: "",
    //       dob: "",
    //       mobilenumber: "",
    //       residentialaddress: "",
    //       gender: "",
    //       martitalstatus: "",
    //       qualification: "",
    //       detailofqualification: "",
    //       machineid: "",
    //       machineserial: "",
    //       doa: "",
    //       dop: "",
    //       designation: "",
    //       department: "",
    //       branch: "",
    //       ait: "",
    //       lia: "",
    //       aot: "",
    //       eoa: "",
    //       remarks: "",
    //       userType_id: "",
    //       base_salary: "",
    //       incentive: "",
    //       conveyance: "",
    //       other_allowance: "",
    //       halfdayin: "",
    //       halfdayout: "",
    //       workinghours: "",
    //       bankaccount: "",
    //       employeebank: "",
    //       isActive: 1
    //     })
    //     fetchEmployeeList()
    //     closeModal2()
    //     toast.success(response.data.message, {
    //       duration: 5000,
    //       position: 'bottom-right',
    //       style: {
    //         borderBottom: "3px solid #61D345",
    //         minWidth: '300px',
    //         minHeight: "50px"
    //       }
    //     })
    //   } else {
    //     setButtonMessage('')
    //     console.error('Failed to update details: ', response.data.error)
    //   }
    // } catch (error) {
    //   setButtonMessage('')
    //   console.error('Error changing employee details: ', error)
    // }
  }

  const editEmployeeSubmit = async (e) => {
    e.preventDefault()
    try {
      setButtonMessage("Submitting")
      const response = await axios.post('/api/editEmployeeSubmit', {
        body: editEmployee
      })
      if (response.data.success) {
        setButtonMessage('')
        setEditEmployee({
          ...editEmployee,
          employeecode: "",
          employeecnic: "",
          employeename: "",
          employeeemail: "",
          guardianname: "",
          // roll: "",
          dob: "",
          mobilenumber: "",
          residentialaddress: "",
          gender: "",
          martitalstatus: "",
          qualification: "",
          detailofqualification: "",
          machineid: "",
          machineserial: "",
          doa: "",
          dop: "",
          designation: "",
          department: "",
          branch: "",
          ait: "",
          lia: "",
          aot: "",
          eoa: "",
          remarks: "",
          userType_id: "",
          base_salary: "",
          incentive: "",
          conveyance: "",
          other_allowance: "",
          halfdayin: "",
          halfdayout: "",
          workinghours: "",
          bankaccount: "",
          employeebank: "",
          isActive: 1
        })
        fetchEmployeeList()
        closeModal2()
        toast.success(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      } else {
        setButtonMessage('')
        console.error('Failed to update details: ', response.data.error)
      }
    } catch (error) {
      setButtonMessage('')
      console.error('Error changing employee details: ', error)
    }
  }

  function handledataIsActive(e) {
    if (e.target.checked) {
      setEditEmployee({ ...editEmployee, [e.target.name]: 1 })
    }
    else {
      setEditEmployee({ ...editEmployee, [e.target.name]: 0 })
    }
  }

  function handleRuleData(e, form) {
    if (e.target.checked) {
      if (e.target.name == "Allow_Admin") {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 1, Allow_View: 1, Allow_Edit: 1, Allow_Add: 1, Allow_Delete: 1 } : item)
        setFetchRules(tempGrid)
      }
      else {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 1 } : item)
        setFetchRules(tempGrid)
      }
    }
    else {
      if (e.target.name == "Allow_Admin") {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 0, Allow_View: 0, Allow_Edit: 0, Allow_Add: 0, Allow_Delete: 0 } : item)
        setFetchRules(tempGrid)
      }
      else {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 0 } : item)
        setFetchRules(tempGrid)
      }
    }
  }

  async function submitRules(e) {
    e.preventDefault()
    try {
      setButtonMessage("Submitting")
      const response = await axios.post('/api/submitRules', {
        body: fetchRules
      })
      if (response.data.success) {
        setButtonMessage('')
        closeModal3()
        toast.success(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      } else {
        setButtonMessage('')
        toast.error(response.data.error, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      }
    } catch (error) {
      setButtonMessage('')
      toast.error(error, {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
    }
  }

  return (
    <>
      {
        newRules.length > 0
          ?
          newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1 || newRules[0].Allow_View == 1
            ?
            <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
              <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between" }}>
                <Box sx={{ display: 'flex' }}>
                  <Card sx={{
                    margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                  }}>{icon}</Card>
                  <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>{title + "s"}</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}
                >
                  <TextField variant="standard"
                    id="scale"
                    name="scale"
                    label="Search By"
                    value={searchBy}
                    onChange={(e) => setSearchBy(e.target.value)}
                    select
                    sx={{ width: { xs: "50%", md: 200 } }}
                  >
                    <MenuItem value="Employee">Employee</MenuItem>
                    <MenuItem value="Branch">Branch</MenuItem>
                    <MenuItem value="Designation">Designation</MenuItem>
                    <MenuItem value="Department">Department</MenuItem>
                    <MenuItem value="Status">Status</MenuItem>
                  </TextField>
                  {/* <Box sx={{ display: 'flex', gap: 1, marginBottom: { xs: '10px', sm: "0px" } }}> */}
                  {searchBy == "Employee" ?
                    <TextField
                      label={`Search ${title}`}
                      type="search"
                      size='small'
                      onChange={(e) => setfilterRowss(e.target.value)}
                      sx={{ width: { xs: "50%", md: 200 } }}
                    />
                    :
                    searchBy == "Branch" ?
                      <TextField variant="standard"
                        id="branch"
                        name="branch"
                        label="Branch"
                        value={searchByValue}
                        onChange={(e) => searchByCriteria(e.target.value)}
                        select
                        sx={{ width: { xs: "50%", md: 200 } }}
                      >
                        {
                          branchList.map((v, i) => {
                            return <MenuItem value={v.branch_name}>{v.branch_name}</MenuItem>
                          })
                        }
                      </TextField>
                      :
                      searchBy == "Designation" ?
                        <TextField variant="standard"
                          id="designation"
                          name="designation_id"
                          label="Designation"
                          value={searchByValue}
                          onChange={(e) => searchByCriteria(e.target.value)}
                          select
                          sx={{ width: { xs: "50%", md: 200 } }}
                        >
                          {
                            designationList.map((v, i) => {
                              return <MenuItem value={v.designation}>{v.designation}</MenuItem>
                            })
                          }
                        </TextField>
                        :
                        searchBy == "Status" ?
                          <TextField variant="standard"
                            id="scale"
                            name="scale"
                            label="Search By"
                            value={searchByValue}
                            onChange={(e) => searchByCriteria(e.target.value)}
                            select
                            sx={{ width: { xs: "50%", md: 200 } }}
                          >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                          </TextField>
                          :
                          <TextField variant="standard"
                            id="department"
                            name="department"
                            label="Department"
                            value={searchByValue}
                            onChange={(e) => searchByCriteria(e.target.value)}
                            select
                            sx={{ width: { xs: "50%", md: 200 } }}
                          >
                            {
                              departmentList.map((v, i) => {
                                return <MenuItem value={v.departmentName}>{v.departmentName}</MenuItem>
                              })
                            }
                          </TextField>
                  }
                  <Button
                    sx={{ height: 40, width: 50, marginTop: { xs: 1, md: 0 } }}
                    size="small" variant='outlined'
                    onClick={() => reset()}
                  >
                    Reset
                  </Button>
                  <Button size="small" variant='outlined'
                    sx={{ height: 40, width: 140, marginTop: { xs: 1, md: 0 } }}
                    disabled={
                      newRules.length > 0
                        ?
                        (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                          ?
                          false
                          :
                          newRules[0].Allow_Add == 1
                            ?
                            false
                            :
                            true
                        :
                        true
                    }
                    onClick={() => setup()}>Add {title}</Button>
                  {/* </Box> */}
                </Box>
              </Box>
              <table className="table table-bordered" id="table-main">
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle", textAlign: "left" }}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>

                {
                  filterRows.length
                    ?
                    filterRows.map((v2, i2) => {
                      if (v2.employee_id) {
                        return <>
                          <tbody>
                            <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                              <td style={{ verticalAlign: "middle", padding: 5, textAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</td>
                              <td style={{ verticalAlign: "middle", padding: 5, textAlign: "left" }}>{v2.employee_id}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.employeename}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.guardianname ? v2.guardianname : "-"}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.machineid}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.employeecnic}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.mobilenumber}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.doa}</td>
                              <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}><span style={{ color: v2.isActive == 1 ? "green" : "red", fontWeight: "bold" }}>{v2.isActive == 1 ? "Active" : "Inactive"}</span></td>
                              <td style={{ padding: 5, textAlign: "center", verticalAlign: "middle" }}>
                                <Button size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                  disabled={
                                    newRules.length > 0
                                      ?
                                      (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                        ?
                                        false
                                        :
                                        newRules[0].Allow_Edit == 1
                                          ?
                                          false
                                          :
                                          true
                                      :
                                      true
                                  }
                                  onClick={() => setupedit(v2)}
                                >
                                  Edit
                                </Button>
                                {/* <Button color="error" size="small" variant='outlined' sx={{ height: 25, width: 60 }} disabled={
                                newRules.length > 0
                                  ?
                                  (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                    ?
                                    false
                                    :
                                    newRules[0].Allow_Delete == 1
                                      ?
                                      false
                                      :
                                      true
                                  :
                                  true
                              }>
                                Delete
                              </Button> */}
                              </td>
                              <td style={{
                                padding: 5, textAlign: "center", verticalAlign: "middle"
                              }}>
                                <Button
                                  size="small"
                                  variant='outlined'
                                  sx={{ height: 25, margin: 0.5, width: 60 }}
                                  disabled={
                                    newRules.length > 0
                                      ?
                                      (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                        ?
                                        false
                                        :
                                        newRules[0].Allow_Edit == 1
                                          ?
                                          false
                                          :
                                          true
                                      :
                                      true
                                  }
                                  // onClick={() => setup()}
                                  onClick={() => setupRights(v2)}
                                >
                                  Rights
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      }
                    })
                    :
                    newRows.map((v2, i2) => {
                      return <>
                        <tbody>
                          <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <td style={{ verticalAlign: "middle", padding: 5, textAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</td>
                            <td style={{ verticalAlign: "middle", padding: 5, textAlign: "left" }}>{v2.employee_id}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.employeename}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.guardianname ? v2.guardianname : "-"}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.machineid}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.employeecnic}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.mobilenumber}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v2.doa}</td>
                            <td style={{ padding: 5, textAlign: "left", verticalAlign: "middle" }}><span style={{ color: v2.isActive == 1 ? "green" : "red", fontWeight: "bold" }}>{v2.isActive == 1 ? "Active" : "Inactive"}</span></td>
                            <td style={{ padding: 5, textAlign: "center", verticalAlign: "middle" }}>
                              <Button
                                size="small"
                                variant='outlined'
                                sx={{ height: 25, margin: 0.5, width: 60 }}
                                disabled={
                                  newRules.length > 0
                                    ?
                                    (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                      ?
                                      false
                                      :
                                      newRules[0].Allow_Edit == 1
                                        ?
                                        false
                                        :
                                        true
                                    :
                                    true
                                }
                                // onClick={() => setup()}
                                onClick={() => setupedit(v2)}
                              >
                                Edit
                              </Button>
                              {/* <Button color="error" size="small" variant='outlined' sx={{ height: 25, margin: 0.5, width: 60 }} disabled={
                                newRules.length > 0
                                  ?
                                  (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                    ?
                                    false
                                    :
                                    newRules[0].Allow_Delete == 1
                                      ?
                                      false
                                      :
                                      true
                                  :
                                  true
                              }>
                                Delete
                              </Button> */}
                            </td>
                            <td
                              style={
                                newRules.length > 0
                                  ?
                                  (newRules[0].User_Is_Super_Admin == 1)
                                    ?
                                    {
                                      padding: 5,
                                      textAlign: "center",
                                      verticalAlign: "middle"
                                    }
                                    :
                                    {
                                      display: "none"
                                    }
                                  :
                                  {
                                    padding: 5,
                                    textAlign: "center",
                                    verticalAlign: "middle"
                                  }
                              }
                            >
                              <Button
                                size="small"
                                variant='outlined'
                                sx={{ height: 25, margin: 0.5, width: 60 }}
                                disabled={
                                  newRules.length > 0
                                    ?
                                    (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                      ?
                                      false
                                      :
                                      newRules[0].Allow_Edit == 1
                                        ?
                                        false
                                        :
                                        true
                                    :
                                    true
                                }
                                // onClick={() => setup()}
                                onClick={() => setupRights(v2)}
                              >
                                Rights
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    })
                }

              </table>

              <table
                className="table"
                id="table-main-small">
                {
                  filterRows.length
                    ?
                    filterRows.map((v1, i1) => {
                      if (v1.employee_id) {
                        return <>
                          <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Employee. ID: {v1.employee_id}</td>
                              <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                                <Button size="small" variant='outlined' sx={{ height: 30, width: 70, marginBottom: 1 }}
                                  disabled={
                                    newRules.length > 0
                                      ?
                                      (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                        ?
                                        false
                                        :
                                        newRules[0].Allow_Edit == 1
                                          ?
                                          false
                                          :
                                          true
                                      :
                                      true
                                  }
                                  onClick={() => setupedit(v1)}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Machine ID: {v1.machineid}</td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Employee Name: {v1.employeename}</td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Guardian Name: {v1.guardianname}</td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >CNIC: {v1.employeecnic}</td>
                              <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                                {/* <Button color="error" size="small" variant='outlined' sx={{ height: 30, width: 70 }} disabled={
                                newRules.length > 0
                                  ?
                                  newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1
                                    ?
                                    false
                                    :
                                    newRules[0].Allow_Delete == 1
                                      ?
                                      false
                                      :
                                      true
                                  :
                                  true
                              }>
                                Delete
                              </Button> */}
                              </td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Mobile: {v1.mobilenumber}</td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Designation: {v1.designation}</td>
                              <td scope="col" id="mobile-style" ></td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Department: {v1.departmentName}</td>
                              <td scope="col" id="mobile-style" ></td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Branch: {v1.branch_name}</td>
                              <td scope="col" id="mobile-style" ></td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Hired Date: {v1.doa}</td>
                              <td scope="col" id="mobile-style" ></td>
                            </tr>
                            <tr key={i1 + 1}>
                              <td scope="col" id="mobile-style" >Status: <span style={{ color: v1.isActive == 1 ? "green" : "red", fontWeight: "bold" }}>{v1.isActive == 1 ? "Active" : "Inactive"}</span></td>
                              <td scope="col" id="mobile-style" ></td>
                            </tr>
                          </tbody>
                          <hr style={{ margin: 5, padding: 0 }} />
                        </>
                      }
                    })
                    :
                    newRows.map((v2, i2) => {
                      return <>
                        <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Employee. ID: {v2.employee_id}</td>
                            <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                              <Button size="small" variant='outlined' sx={{ height: 30, width: 70, marginBottom: 1 }}
                                disabled={
                                  newRules.length > 0
                                    ?
                                    (newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1)
                                      ?
                                      false
                                      :
                                      newRules[0].Allow_Edit == 1
                                        ?
                                        false
                                        :
                                        true
                                    :
                                    true
                                }
                                onClick={() => setupedit(v2)}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Machine ID: {v2.machineid}</td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Employee Name: {v2.employeename}</td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Guardian Name: {v2.guardianname}</td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scopescope="col" id="mobile-style" >CNIC: {v2.employeecnic}</td>
                            <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                              {/* <Button color="error" size="small" variant='outlined' sx={{ height: 30, width: 70 }} disabled={
                                newRules.length > 0
                                  ?
                                  newRules[0].User_Is_Super_Admin == 1 || newRules[0].Allow_Admin == 1
                                    ?
                                    false
                                    :
                                    newRules[0].Allow_Delete == 1
                                      ?
                                      false
                                      :
                                      true
                                  :
                                  true
                              }>
                                Delete
                              </Button> */}
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Mobile: {v2.mobilenumber}</td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Designation: {v2.designation}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Department: {v2.departmentName}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Branch: {v2.branch_name}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Hired Date: {v2.doa}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Status: <span style={{ color: v2.isActive == 1 ? "green" : "red", fontWeight: "bold" }}>{v2.isActive == 1 ? "Active" : "Inactive"}</span></td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                        </tbody>
                        <hr style={{ margin: 5, padding: 0 }} />
                      </>
                    })
                }
              </table>
            </Box >
            :
            <div style={{ padding: 10 }}>View disabled</div>
          :
          ""
      }
      <Dialog
        open={modalIsOpen2}
        onClose={closeModal2}
        PaperProps={{
          component: 'form',
          sx: {
            width: { xs: '100%', sm: '70%' },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Edit Employee`}</DialogTitle>
        <DialogContent sx={{
          '& .MuiInputBase-input': { fontSize: '1.5rem' },
          '& .MuiInputLabel-root': { fontSize: '1.25rem' },
        }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField autoFocus required fullWidth variant="standard"
                id="employeecode"
                name="employeecode"
                label="Emloyee ID"
                type="number"
                value={editEmployee.employeecode}
                onChange={handledata}
              // onBlur={handleBluremployeecode}
              // error={ErrorAlertOpenemployeecode} 
              />
              <MaskedTextField required fullWidth variant="standard"
                // error={ErrorAlertOpenemployeecnic}
                id="employeecnic"
                name="employeecnic"
                label="Emloyee CNIC"
                type="text"
                value={editEmployee.employeecnic}
                onChange={handledata}
                mask="00000-0000000-0"
                placeholder="00000-0000000-0"
              // onBlur={handleBluremployeecnic} 
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                // error={ErrorAlertOpenemployeename}
                id="employeename"
                name="employeename"
                label="Emloyee Name"
                type="text"
                value={editEmployee.employeename}
                onChange={handledata}
              // onBlur={handleBluremployeename} 
              />
              <TextField fullWidth variant="standard"
                id="guardianname"
                name="guardianname"
                label="Guardian Name"
                type="text"
                value={editEmployee.guardianname}
                onChange={handledata} />
            </Box>
            {/* <TextField fullWidth variant="standard"
              id="roll"
              name="roll"
              label="Roll"
              type="text"
              value={inputData.roll}
              onChange={handledata} /> */}
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                // error={ErrorAlertOpenmachineid}
                id="machineid"
                name="machineid"
                label="Machine ID"
                type="text"
                value={editEmployee.machineid}
                onChange={handledata}
              // onBlur={handleBlurmachineid}
              />

              <TextField required fullWidth variant="standard"
                // error={ErrorAlertOpenmachineserial}
                id="machineserial"
                name="machineserial"
                label="Machine Serial"
                type="text"
                value={editEmployee.machineserial}
                onChange={handledata}
              // onBlur={handleBlurmachineserial}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="dob"
                name="dob"
                label="Date of Birth"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '9999-12-31',
                    min: '1000-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={editEmployee.dob}
                onChange={handledata}
              // error={ErrorAlertOpendob}
              // onBlur={handleBlurdob}
              />
              <MaskedTextField fullWidth required variant="standard"
                id="mobilenumber"
                name="mobilenumber"
                label="Mobile Number"
                type="text"
                value={editEmployee.mobilenumber}
                onChange={handledata}
                mask="0000-0000000"
                placeholder="0300-1234567"
              // error={ErrorAlertOpenmobilenumber}
              // onBlur={handleBlurmobilenumber}
              />
            </Box>
            <TextField required fullWidth variant="standard"
              id="residentialaddress"
              name="residentialaddress"
              label="Residential Address"
              type="text"
              value={editEmployee.residentialaddress}
              onChange={handledata}
            // error={ErrorAlertOpenresidentialaddress}
            // onBlur={handleBlurresidentialaddress}
            />
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
              <TextField required fullWidth variant="standard"
                id="employeeemail"
                name="employeeemail"
                label="Employee Email"
                type="text"
                value={editEmployee.employeeemail}
                onChange={handledata}
              // error={ErrorAlertOpenresidentialaddress}
              // onBlur={handleBlurresidentialaddress}
              />
              <FormControlLabel
                label="Active"
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    onChange={handledataIsActive}
                    checked={editEmployee.isActive == 1 ? true : false} // or checked={false} based on your initial state
                  />
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="gender"
                name="gender"
                label="Gender"
                value={editEmployee.gender}
                onChange={handledata}
                // error={ErrorAlertOpengender}
                // onClick={handleBlurgender}
                select>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="transgender">Transgender</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
              <TextField required fullWidth variant="standard"
                id="martitalstatus"
                name="martitalstatus"
                label="Marital Status"
                value={editEmployee.martitalstatus}
                onChange={handledata}
                select>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="unmarried">Un Married</MenuItem>
                <MenuItem value="separated">Separated</MenuItem>
                <MenuItem value="Widow">Widow</MenuItem>
                <MenuItem value="widower">Widower</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="qualification"
                name="qualification"
                label="Qualification"
                value={editEmployee.qualification}
                onChange={handledata}
                select>
                <MenuItem value="Middle">Middle</MenuItem>
                <MenuItem value="Matric">Matric</MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="graduation">Graduation</MenuItem>
                <MenuItem value="master">Masters</MenuItem>
                <MenuItem value="Phd">Phd</MenuItem>
                <MenuItem value="other">Others</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="detailofqualification"
                name="detailofqualification"
                label="Detail of Qualification"
                type="text"
                value={editEmployee.detailofqualification}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="doa"
                name="doa"
                label="Appointment Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '9999-12-31',
                    min: '1000-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={editEmployee.doa}
                onChange={handledata}
              // error={ErrorAlertOpendoa}
              // onBlur={handleBlurdoa}
              />
              <TextField required fullWidth variant="standard"
                id="dop"
                name="dop"
                label="Probation End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '9999-12-31',
                    min: '1000-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={editEmployee.dop}
                onChange={handledata}
              // error={ErrorAlertOpendop}
              // onBlur={handleBlurdop} 
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="designation"
                name="designation_id"
                label="Designation"
                value={editEmployee.designation_id}
                onChange={handledata}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  designationList.map((v, i) => {
                    return <MenuItem value={v.designation_id}>{v.designation}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="department"
                name="department"
                label="Department"
                value={editEmployee.department}
                onChange={handledataDept}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  departmentList.map((v, i) => {
                    return <MenuItem value={v.department_id}>{v.departmentName}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="branch"
                name="branch"
                label="Branch"
                value={editEmployee.branch}
                onChange={handledata}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  branchList.map((v, i) => {
                    return <MenuItem value={v.branch_id}>{v.branch_name}</MenuItem>
                  })
                }
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="ait"
                name="ait"
                label="Applied In Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.ait}
                onChange={handledata}
              // error={ErrorAlertOpenait}
              // onBlur={handleBlurait}
              />
              <TextField required fullWidth variant="standard"
                id="lia"
                name="lia"
                label="Late In Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.lia}
                onChange={handledata}
              // error={ErrorAlertOpenlia}
              // onBlur={handleBlurlia}
              />
              <TextField required fullWidth variant="standard"
                id="halfdayin"
                name="halfdayin"
                label="Half Day In Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.halfdayin}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="aot"
                name="aot"
                label="Applied Out Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.aot}
                onChange={handledata}
              // error={ErrorAlertOpenaot}
              // onBlur={handleBluraot}
              />
              <TextField required fullWidth variant="standard"
                id="eoa"
                name="eoa"
                label="Early Out Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.eoa}
                onChange={handledata}
              // error={ErrorAlertOpeneoa}
              // onBlur={handleBlureoa}
              />
              <TextField required fullWidth variant="standard"
                id="halfdayout"
                name="halfdayout"
                label="Half Day Out Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.halfdayout}
                onChange={handledata}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="employeebank"
                name="employeebank"
                label="Bank"
                type="text"
                value={editEmployee.employeebank}
                onChange={handledata}
              // error={ErrorAlertOpenconveyance}
              // onBlur={handleBlurconveyance} 
              />
              <TextField required fullWidth variant="standard"
                id="bankaccount"
                name="bankaccount"
                label="Account"
                type="number"
                value={editEmployee.bankaccount}
                onChange={handledata}
              // error={ErrorAlertOpenother_allowance}
              // onBlur={handleBlurother_allowance}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="base_salary"
                name="base_salary"
                label="Base Salary"
                type="number"
                value={editEmployee.base_salary}
                onChange={handledata}
              // error={ErrorAlertOpenbase_salary}
              // onBlur={handleBlurbase_salary}
              />
              <TextField required fullWidth variant="standard"
                id="incentive"
                name="incentive"
                label="Incentive allowance"
                type="number"
                value={editEmployee.incentive}
                onChange={handledata}
              // error={ErrorAlertOpenincentive}
              // onBlur={handleBlurincentive}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="conveyance"
                name="conveyance"
                label="Conveyance allowance"
                type="number"
                value={editEmployee.conveyance}
                onChange={handledata}
              // error={ErrorAlertOpenconveyance}
              // onBlur={handleBlurconveyance}
              />
              <TextField required fullWidth variant="standard"
                id="other_allowance"
                name="other_allowance"
                label="Other allowance"
                type="number"
                value={editEmployee.other_allowance}
                onChange={handledata}
              // error={ErrorAlertOpenother_allowance}
              // onBlur={handleBlurother_allowance}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="workinghours"
                name="workinghours"
                label="Working Hours:"
                value={editEmployee.workinghours}
                onChange={handledata}
                select
              >
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="remarks"
                name="remarks"
                label="Remarks & Other Notes"
                type="text"
                // multiline
                // rows={4}
                value={editEmployee.remarks}
                onChange={handledata} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal2}
            disabled={buttonMessage !== '' ? true : false}

          >
            Cancel
          </Button>
          <Button type="submit"
            onClick={editEmployeeSubmit}
            disabled={buttonMessage !== '' ? true : false}
          >
            {buttonMessage !== '' ? buttonMessage : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalIsOpen3}
        onClose={closeModal3}
        PaperProps={{
          component: 'form',
          sx: {
            width: { xs: '100%', sm: '70%' },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Employee Rights`}</DialogTitle>
        <DialogContent sx={{
          '& .MuiInputBase-input': { fontSize: '1.5rem' },
          '& .MuiInputLabel-root': { fontSize: '1.25rem' },
          overflow: "hidden"
        }}>
          <table className="table table-bordered" id="table-main">
            <tr >
              <td style={{ textAlign: "left" }}>
                Form Name
              </td>
              <td style={{ width: 82 }}>
                Admin
              </td>
              <td style={{ width: 82 }}>
                View
              </td>
              <td style={{ width: 82 }}>
                Add
              </td>
              <td style={{ width: 82 }}>
                Edit
              </td>
              <td style={{ width: 82 }}>
                Delete
              </td>
            </tr>
          </table>
          <Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row", height: 500, overflow: "scroll", paddingBottom: 100 }, gap: 2 }} >
              <table className="table table-bordered" id="table-main" >
                {/* <tr>
                  <td style={{ textAlign: "left" }}>
                    Form Name
                  </td>
                  <td>
                    Admin
                  </td>
                  <td>
                    View
                  </td>
                  <td>
                    Add
                  </td>
                  <td>
                    Edit
                  </td>
                  <td>
                    Delete
                  </td>
                </tr> */}
                {
                  fetchRules.map((v, i) => {
                    return <tr>
                      <td style={{ textAlign: "left" }}>{v.Object_Caption}</td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Admin"
                              name="Allow_Admin"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Admin == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_View"
                              name="Allow_View"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_View == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        {/* {v.Allow_Add} */}
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Add"
                              name="Allow_Add"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Add == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        {/* {v.Allow_Edit} */}
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Edit"
                              name="Allow_Edit"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Edit == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        {/* {v.Allow_Delete} */}
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Delete"
                              name="Allow_Delete"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Delete == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                    </tr>
                  })
                }
              </table>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal3}
            disabled={buttonMessage !== '' ? true : false}

          >
            Cancel
          </Button>
          <Button type="submit"
            onClick={submitRules}
            disabled={buttonMessage !== '' ? true : false}
          >
            {buttonMessage !== '' ? buttonMessage : "Submit"}
          </Button>
        </DialogActions>
      </Dialog >
      <Toaster />
    </>
  )
}