import React from 'react'
import NavigationBar from './navigation'
import NavForEmployee from './navForEmployee'
import DefaultNavBar from './defaultNavBar'

const Layout = ({ children }) => {
  return (
    <div>
      <NavigationBar />
      {children}
    </div>
  )
}

const LayoutForEmployee = ({ children }) => {
  return (
    <div>
      <NavForEmployee />
      {children}
    </div>
  )
}

const LayoutDefault = ({ children }) => {
  return (
    <div>
      <DefaultNavBar />
      {children}
    </div>
  )
}

export { Layout, LayoutForEmployee, LayoutDefault }