import * as React from 'react'
import { useState } from 'react'
import { Alert, Avatar, Button, TextField, Link, Box, Typography } from '@mui/material'
import { avatarImage, BackgroundImage } from "./Img/Img"
import { LockOpenRounded } from '@mui/icons-material'
import axios from "axios"
import { useAuth } from '../AuthContext'
import { useNavigate } from 'react-router-dom'

function Login() {

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const cnicRegex = /^\d{5}-\d{7}-\d$/

    const navigate = useNavigate()
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [message, setMessage] = useState(null)
    const [isAuth, setIsAuth] = useState()
    const { login } = useAuth()
    const [EmailErrorAlertOpen, setEmailErrorAlertOpen] = useState(false)
    const [passwordErrorAlertOpen, setPasswordErrorAlertOpen] = useState(false)
    const [buttonMessage, setButtonMessage] = useState('')

    function handleEmailNameBlur() {
        if (Email.match(emailRegex) || Email.match(cnicRegex)) {
            return setEmailErrorAlertOpen(false)
        } else {
            return setEmailErrorAlertOpen(true)
        }
    }

    function handlePasswordBlur() {
        if (!Password.length > 0) {
            setPasswordErrorAlertOpen(true)
            return
        } else {
            setPasswordErrorAlertOpen(false)
        }
    }

    const handleLogin = async () => {
        if (!EmailErrorAlertOpen && !passwordErrorAlertOpen) {
            try {
                setButtonMessage("signing in")
                const response = await axios.post('/api/login', { Email: Email, Password: Password })
                if (response.data.isAuth) {
                    setButtonMessage("")
                    setIsAuth(true)
                    setMessage(response.data.message)
                }
                else {
                    setButtonMessage("")
                    setIsAuth(false)
                    setMessage(response.data.message)
                }
                login(response.data.token, response.data.id, response.data, response.data.email, response.data.eid, response.data.eUserInfo)
            }
            catch (error) {
                setIsAuth(false)
                setMessage("Something went wrong. Please try again later")
            }
        }
    }

    function route() {
        navigate('/signin')
    }

    function forgotPassword() {
        navigate('/forgottenPassword')
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: { sm: `url(${BackgroundImage})` },
                    width: "100%",
                    height: { xs: "80vh", sm: "100vh" },
                    backgroundSize: "100% 100vh",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        margin: 2,
                        gap: { xs: 0, sm: 0, md: 20, lg: 30 },
                        flexDirection: 'row',
                        alignItems: 'top',
                        justifyItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            margin: 2,
                            display: { xs: "none", sm: "none", md: "flex" },
                            flexDirection: 'column',
                            alignItems: 'left',
                            // justifyItems: 'center',
                            // justifyContent: 'center',
                            maxWidth: { xs: "fullWidth", sm: "60%", md: "50%", lg: "45%" },
                            // border: { xs: 1, sm: "" },
                            // borderRadius: '8px',
                            // borderColor: { xs: "lightgrey", sm: "" },
                            // border: "1px solid red"
                        }}
                    >
                        <Typography variant="h3" style={{ color: "white" }}>PAYROLL</Typography>
                        <Typography variant="h3" style={{ color: "white" }}>MANAGEMENT SYSTEM</Typography>
                    </Box>
                    <Box
                        sx={{
                            margin: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            maxWidth: { xs: "fullWidth", sm: "60%", md: "50%", lg: "35%" },
                            border: { xs: 1, sm: "" },
                            borderRadius: '8px',
                            borderColor: { xs: "lightgrey", sm: "" },
                            backgroundColor: "white",

                        }}
                    >
                        <Avatar alt="Simple Payroll" src={avatarImage} sx={{ width: 56, height: 56, mt: 3, borderRadius: 0 }} />
                        <Typography variant="h4">Log In</Typography>
                        <Box sx={{ mt: 3, margin: 2 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                required
                                fullWidth
                                label="Email/CNIC"
                                type="email"
                                id="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleEmailNameBlur}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                required
                                fullWidth
                                label="Password"
                                type="Password"
                                id="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={handlePasswordBlur}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", alignContent: "flex-end" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ width: "50%", mt: 3, mb: 2 }}
                                    startIcon={<LockOpenRounded />}
                                    onClick={() => handleLogin()}
                                    disabled={buttonMessage !== '' ? true : false}
                                >
                                    {buttonMessage !== '' ? buttonMessage : "sign in"}
                                </Button>
                                <Link
                                    sx={{ ml: 0 }}
                                    variant="body2"
                                    style={{
                                        textDecoration: "none",
                                        cursor: "pointer"
                                    }} >
                                    <span
                                        onClick={route}
                                    >Don't have an account? Sign Up
                                    </span>
                                    <br />
                                    <span
                                        onClick={forgotPassword}
                                    >Forgotten password?
                                    </span>
                                </Link>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                {EmailErrorAlertOpen &&
                                    <Alert severity="error">
                                        Invalid email/CNIC
                                    </Alert>
                                }
                                {passwordErrorAlertOpen &&
                                    <Alert severity="error" sx={{ mt: 1 }}>
                                        Please type password
                                    </Alert>
                                }
                                {message && (
                                    <Alert severity={isAuth ? "success" : "error"}>
                                        {message}
                                    </Alert>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Login