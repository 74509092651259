import React, { useState, useEffect } from 'react'
import axios from "axios"
import { Box, TextField, Card, Typography } from "@mui/material"
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import BusinessIcon from '@mui/icons-material/Business'
import "./css/pages.css"
import toast, { Toaster } from 'react-hot-toast'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

const Admin = () => {

    const [companies, setCompanies] = useState([])
    const [filterArr, setFilterArr] = useState([])

    async function handledataIsActive(e, company_id) {
        let flag
        if (e.target.checked) {
            flag = 1
        }
        else {
            flag = 0
        }
        const response = await axios.post('/api/changeCompanyStatus', {
            body: {
                isActive: flag,
                company_id: company_id
            }
        })
        if (response.data.success) {
            fetchCompantList()
            toast.success(response.data.message, {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    borderBottom: "3px solid #61D345",
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            toast.error(response.data.message, {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }
    }

    function changeTimeRegion(dateString) {
        if (dateString !== undefined) {
            const utcDate = new Date(dateString)

            const formatter = new Intl.DateTimeFormat('en-PK', {
                timeZone: 'Asia/Karachi',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                // hour: '2-digit',
                // minute: '2-digit',
                // second: '2-digit'
            })

            let formValue = formatter.format(utcDate)

            const year = formValue.slice(6, 10)
            const month = formValue.slice(3, 5)
            const day = formValue.slice(0, 2)

            // Format the date as "YYYY-MM-DD"
            const formattedDate = `${day}-${month}-${year}`

            return formattedDate
        }
        // return undefined
    }

    const fetchCompantList = async () => {
        try {
            const response = await axios.post('/api/fetchCompanies')
            if (response.data.response) {
                setCompanies(response.data.data)
            }
            else {
                console.error('Failed to fetch company list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching company list: ', error)
        }
    }

    useEffect(() => {
        fetchCompantList()
    }, [])

    function filterFunc(e) {
        let temp = companies.filter((v, i) => {
            return v.company_name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                <Box sx={{ display: 'flex' }}>
                    <Card sx={{
                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                    }}>{<BusinessIcon fontSize="large" />}</Card>
                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Companies</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                    <TextField
                        style={{ marginRight: 5 }}
                        onChange={(e) => filterFunc(e.target.value)}

                        label={`Search Company`}
                        type="search"
                        size='small'
                        sx={{ width: { sx: "100%" }, }}
                    />
                </Box>
            </Box>
            <table className="table table-bordered" id="table-main">
                <thead>
                    <tr>
                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Company</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Registration Date</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Status</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th>
                    </tr>
                </thead>
                {
                    filterArr.length
                        ?
                        filterArr.map((v2, i2) => {
                            return <>
                                <tbody>
                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.company_name}</td>
                                        <td style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{v2.registration_date == '' ? '' : changeTimeRegion(v2.registration_date)}</td>
                                        <td style={v2.isActive == 1 ? { color: "green", padding: 2, textAlign: "center", verticalAlign: "middle" } : { color: "red", padding: 2, textAlign: "center", verticalAlign: "middle" }}>{v2.isActive == 1 ? "Active" : "Inactive"}</td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            <FormControlLabel
                                                // label="Active"
                                                control={
                                                    <Switch
                                                        id="isActive"
                                                        name="isActive"
                                                        onChange={(e) => handledataIsActive(e, v2.company_id)}
                                                        checked={v2.isActive == 1 ? true : false}
                                                    />
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                        })
                        :
                        companies.map((v2, i2) => {
                            return <>
                                <tbody>
                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.company_name}</td>
                                        <td style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{v2.registration_date == '' ? '' : changeTimeRegion(v2.registration_date)}</td>
                                        <td style={v2.isActive == 1 ? { color: "green", padding: 2, textAlign: "center", verticalAlign: "middle" } : { color: "red", padding: 2, textAlign: "center", verticalAlign: "middle" }}>{v2.isActive == 1 ? "Active" : "Inactive"}</td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            <FormControlLabel
                                                // label="Active"
                                                control={
                                                    <Switch
                                                        id="isActive"
                                                        name="isActive"
                                                        onChange={(e) => handledataIsActive(e, v2.company_id)}
                                                        checked={v2.isActive == 1 ? true : false}
                                                    />
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                        })
                }
            </table>
            <table
                className="table"
                id="table-main-small">
                {
                    filterArr.length
                        ?
                        filterArr.map((v1, i1) => {
                            return <>
                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Company: {v1.company_name}</td>
                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Registration Date: {v1.registration_date == '' ? '' : changeTimeRegion(v1.registration_date)}</td>
                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Status: {v1.isActive == 1 ? "Active" : "Inactive"}</td>
                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >
                                            <FormControlLabel
                                                // label="Active"
                                                control={
                                                    <Switch
                                                        id="isActive"
                                                        name="isActive"
                                                        onChange={(e) => handledataIsActive(e, v1.company_id)}
                                                        checked={v1.isActive == 1 ? true : false}
                                                    />
                                                }
                                            />
                                        </td>
                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                </tbody>
                                <hr style={{ margin: 5, padding: 0 }} />
                            </>
                        })
                        :
                        companies.map((v1, i1) => {
                            return <>
                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Company: {v1.company_name}</td>
                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Registration Date: {v1.registration_date == '' ? '' : changeTimeRegion(v1.registration_date)}</td>
                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >Status: {v1.isActive == 1 ? "Active" : "Inactive"}</td>
                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                    <tr key={i1 + 1}>
                                        <td scope="col" id="mobile-style" >
                                            <FormControlLabel
                                                // label="Active"
                                                control={
                                                    <Switch
                                                        id="isActive"
                                                        name="isActive"
                                                        onChange={(e) => handledataIsActive(e, v1.company_id)}
                                                        checked={v1.isActive == 1 ? true : false}
                                                    />
                                                }
                                            />
                                        </td>
                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}></td>
                                    </tr>
                                </tbody>
                                <hr style={{ margin: 5, padding: 0 }} />
                            </>
                        })
                }
            </table>
            <Toaster />
        </Box >
    )
}

export default Admin