import React, { useState, useEffect } from 'react'
import axios from "axios"
import Cookies from 'js-cookie'
import { Box, Button, TextField, Card, Typography, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import toast, { Toaster } from 'react-hot-toast'

const Department = () => {

    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [filterArr, setFilterArr] = useState([])
    const [editDepartment, setEditDepartment] = useState({})
    const [modalIsOpen2, setIsOpen2] = useState(false)
    const [rules, setRules] = useState([])

    const [buttonMessage, setButtonMessage] = useState('')

    const openModal1 = () => {
        setIsOpen1(true)
    }

    const setupedit = (v) => {
        setEditDepartment(v)
        setIsOpen2(true)
    }

    function handledata(e) {
        setEditDepartment({ ...editDepartment, [e.target.name]: e.target.value })
    }

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const editDepartmentSubmit = async (e) => {
        e.preventDefault()
        try {
            setButtonMessage('Submitting')
            const response = await axios.post('/api/editDepartmentSubmit', {
                body: editDepartment
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                fetchDepartmentList()
                closeModal2()
            } else {
                setButtonMessage('')
                console.error("Failed to edit department: ", response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error editing department: ', error)
        }
    }

    async function deletefunc(v) {
        try {
            const response = await axios.post('/api/deleteDepartment', {
                body: v
            })
            if (response.data.response) {
                fetchDepartmentList()
                toast.success("Successfully deleted", {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                console.error('Failed to delete department: ', response.data.error)
            }
        } catch (error) {
            console.error('Error deleting department: ', error)
        }
    }

    const closeModal1 = () => {
        setIsOpen1(false)
        setDepartmentData({
            ...departmentData,
            departmentName: '',
            // category: '',
            inTime: '',
            late: '',
            outTime: '',
            early: '',
            overtime: '',
            deduction: '',
            halfDayOnLate: '',
            halfdayin: '',
            halfdayout: '',
            workinghours: ''
            // fullDayOnHalfDay: '',
            // halfOvertime: '',
            // fullOvertime: ''
        })

    }

    const [departmentData, setDepartmentData] = useState({
        company_id: Cookies.get('userInfo'),
        departmentName: '',
        // category: '',
        inTime: '',
        late: '',
        outTime: '',
        early: '',
        overtime: '',
        deduction: '',
        halfDayOnLate: '',
        halfdayin: '',
        halfdayout: '',
        workinghours: ''
        // fullDayOnHalfDay: '',
        // halfOvertime: '',
        // fullOvertime: '',
    })

    const [departmentList, setDepartmentList] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setDepartmentData({
            ...departmentData,
            [name]: value,
        })
    }

    const addDepartment = async (e) => {
        e.preventDefault()
        if (departmentData.departmentName === "" || departmentData.inTime === "" || departmentData.late === "" || departmentData.halfdayin === "" || departmentData.outTime === "" || departmentData.early === "" || departmentData.halfdayout === "" || departmentData.overtime === "" || departmentData.deduction === "" || departmentData.workinghours === ""
            // || departmentData.halfDayOnLate === "" || departmentData.fullDayOnHalfDay === "" || departmentData.halfOvertime === "" || departmentData.fullOvertime === ""
        ) {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            try {
                setButtonMessage("Adding")
                const response = await axios.post('/api/addDepartment', {
                    body: departmentData
                })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    closeModal1()
                    fetchDepartmentList()
                } else {
                    setButtonMessage('')
                    console.error('Failed to add department: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error adding department: ', error)
            }
        }
    }

    const fetchDepartmentList = async () => {
        try {
            const response = await axios.post('/api/fetchDepartmentList', {
                id: Cookies.get('userInfo')
            })
            if (response.data.response) {
                setDepartmentList(response.data.data)
            }
            else {
                console.error('Failed to fetch department list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching department list: ', error)
        }
    }

    const fetchRules = async () => {
        let id = Cookies.get('eid')
        let userInfo = Cookies.get('userInfo')
        if (id) {
            try {
                const response = await axios.post('/api/fetchRules', {
                    id: id,
                    module: "frm_Department_List"
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
        else {
            try {
                const response = await axios.post('/api/fetchRules', {
                    userInfo: userInfo,
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
    }

    useEffect(() => {
        fetchDepartmentList()
        fetchRules()
    }, [])

    function filterFunc(e) {
        let temp = departmentList.filter((v, i) => {
            return v.departmentName.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    return (
        <>
            {
                rules.length > 0
                    ?
                    (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
                        ?
                        <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Card sx={{
                                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    }}>{<AccountTreeIcon fontSize="large" />}</Card>
                                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Departments</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                                    <TextField
                                        style={{ marginRight: 5 }}
                                        onChange={(e) => filterFunc(e.target.value)}

                                        label={`Search Department`}
                                        type="search"
                                        size='small'
                                        sx={{ width: { sx: "100%" }, }}
                                    />
                                    <Button
                                        size="small" variant='outlined' sx={{ height: 40, width: 160 }}
                                        onClick={() => openModal1()}
                                        disabled={
                                            rules.length > 0
                                                ?
                                                (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                    ?
                                                    false
                                                    :
                                                    rules[0].Allow_Add == 1
                                                        ?
                                                        false
                                                        :
                                                        true
                                                :
                                                true
                                        }
                                    >
                                        Add Department</Button>
                                </Box>
                            </Box>
                            <table className="table table-bordered" id="table-main">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Department</th>
                                        {/* <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Dept. Category</th> */}
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>In Time</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Late In</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Half Day In</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Out Time</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Early Out</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Half Day Out</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Deduction</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Half Day Deduction</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Overtime</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Working Hours</th>
                                        {/* <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Full Day Deduction</th> */}
                                        {/* <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Half Overtime</th>
                                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Full OverTime</th> */}
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th>
                                    </tr>
                                </thead>
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.category}</td> */}
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.inTime}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.late}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfdayin}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.outTime}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.early}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfdayout}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.deduction ? v2.deduction == "no" ? "No" : "Yes" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfDayOnLate ? v2.halfDayOnLate + " lates" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.overtime ? v2.overtime == "no" ? "No" : "Yes" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.workinghours}</td>
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.fullDayOnHalfDay ? v2.fullDayOnHalfDay + " half days" : ""}</td> */}
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfOvertime ? v2.halfOvertime + " minutes" : ""}</td>
                                                    <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.fullOvertime ? v2.fullOvertime + " minutes" : ""}</td> */}
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                        :
                                        departmentList.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.category}</td> */}
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.inTime}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.late}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfdayin}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.outTime}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.early}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfdayout}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.deduction ? v2.deduction == "no" ? "No" : "Yes" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfDayOnLate ? v2.halfDayOnLate + " lates" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.overtime ? v2.overtime == "no" ? "No" : "Yes" : ""}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.workinghours}</td>
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.fullDayOnHalfDay ? v2.fullDayOnHalfDay + " half days" : ""}</td> */}
                                                        {/* <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.halfOvertime ? v2.halfOvertime + " minutes" : ""} </td>
                                                    <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.fullOvertime ? v2.fullOvertime + " minutes" : ""}</td> */}
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                }
                            </table>

                            <table
                                className="table"
                                id="table-main-small">
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Department: {v1.departmentName}</td>
                                                        <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Dept. Category: {v1.category}</td>
                                                </tr> */}
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >In Time: {v1.inTime}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Late In: {v1.late}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day In: {v1.halfdayin}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Out Time: {v1.outTime}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Early Out: {v1.early}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day Out: {v1.halfdayout}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Deduction: {v1.deduction ? v1.deduction == "no" ? "No" : "Yes" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day Deduction: {v1.halfDayOnLate ? v1.halfDayOnLate + " lates" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Overtime: {v1.overtime ? v1.overtime == "no" ? "No" : "Yes" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Working Hours: {v1.workinghours}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Full Day Deduction: {v1.fullDayOnHalfDay ? v1.fullDayOnHalfDay + " half days" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr> */}
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Half Overtime: {v1.halfOvertime ? v1.halfOvertime + " minutes" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr>
                                                <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Full Overtime: {v1.fullOvertime ? v1.fullOvertime + " minutes" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr> */}
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                        :
                                        departmentList.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Department: {v1.departmentName}</td>
                                                        <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Dept. Category: {v1.category}</td>
                                                </tr> */}
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >In Time: {v1.inTime}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Late In :{v1.late}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day In: {v1.halfdayin}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Out Time: {v1.outTime}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Early Out: {v1.early}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day Out: {v1.halfdayout}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Deduction: {v1.deduction ? v1.deduction == "no" ? "No" : "Yes" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Half Day Deduction: {v1.halfDayOnLate ? v1.halfDayOnLate + " lates" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Overtime: {v1.overtime ? v1.overtime == "no" ? "No" : "Yes" : ""}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Working Hours: {v1.workinghours}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Full Day Deduction: {v1.fullDayOnHalfDay ? v1.fullDayOnHalfDay + " half days" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr> */}
                                                    {/* <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Half Overtime: {v1.halfOvertime ? v1.halfOvertime + " minutes" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr>
                                                <tr key={i1 + 1}>
                                                    <td scope="col" id="mobile-style" >Full Overtime: {v1.fullOvertime ? v1.fullOvertime + " minutes" : ""}</td>
                                                    <td scope="col" id="mobile-style" ></td>
                                                </tr> */}
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                }
                            </table>
                            <Box>
                                <Dialog
                                    open={modalIsOpen1}
                                    onClose={closeModal1}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }}  >{`Add Department`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box display="flex" flexDirection="row" gap={2}>
                                                <TextField required fullWidth variant="standard"
                                                    id="departmentName"
                                                    name="departmentName"
                                                    label="Department Name:"
                                                    type="text"
                                                    value={departmentData.departmentName}
                                                    onChange={handleInputChange}
                                                />
                                                {/* <TextField required fullWidth variant="standard"
                                                id="category"
                                                name="category"
                                                label="Category:"
                                                type="text"
                                                value={departmentData.category}
                                                onChange={handleInputChange}
                                            /> */}
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="inTime"
                                                    name="inTime"
                                                    label="In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.inTime}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="late"
                                                    name="late"
                                                    label="Late In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.late}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="halfdayin"
                                                    name="halfdayin"
                                                    label="Half Day In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.halfdayin}
                                                    onChange={handleInputChange}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="outTime"
                                                    name="outTime"
                                                    label="Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.outTime}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="early"
                                                    name="early"
                                                    label="Early Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.early}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="halfdayout"
                                                    name="halfdayout"
                                                    label="Half Day Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={departmentData.halfdayout}
                                                    onChange={handleInputChange}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="deduction"
                                                    name="deduction"
                                                    label="Deduction:"
                                                    value={departmentData.deduction}
                                                    onChange={handleInputChange}
                                                    select
                                                >
                                                    <MenuItem value="yes">Yes</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                </TextField>
                                                <TextField fullWidth variant="standard"
                                                    id="halfDayOnLate"
                                                    name="halfDayOnLate"
                                                    label="1 Half Day Deduction on Late:"
                                                    type="number"
                                                    value={departmentData.halfDayOnLate}
                                                    onChange={handleInputChange}
                                                    disabled={departmentData.deduction == "no" ? true : false}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="overtime"
                                                    name="overtime"
                                                    label="Overtime:"
                                                    value={departmentData.overtime}
                                                    onChange={handleInputChange}
                                                    select
                                                >
                                                    <MenuItem value="yes">Yes</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                </TextField>
                                                <TextField required fullWidth variant="standard"
                                                    id="workinghours"
                                                    name="workinghours"
                                                    label="Working Hours:"
                                                    value={departmentData.workinghours}
                                                    onChange={handleInputChange}
                                                    select
                                                >
                                                    <MenuItem value="4">4</MenuItem>
                                                    <MenuItem value="5">5</MenuItem>
                                                    <MenuItem value="6">6</MenuItem>
                                                    <MenuItem value="7">7</MenuItem>
                                                    <MenuItem value="8">8</MenuItem>
                                                    <MenuItem value="9">9</MenuItem>
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="11">11</MenuItem>
                                                    <MenuItem value="12">12</MenuItem>
                                                </TextField>
                                                {/* <TextField fullWidth variant="standard"
                                                id="fullDayOnHalfDay"
                                                name="fullDayOnHalfDay"
                                                label="1 Full Day (Half Days):"
                                                type="number"
                                                value={departmentData.fullDayOnHalfDay}
                                                onChange={handleInputChange}
                                                disabled={departmentData.deduction == "no" ? true : false}
                                            /> */}
                                            </Box>
                                            <Box display="flex" flexDirection="row" gap={2}>
                                                {/* <TextField fullWidth variant="standard"
                                                id="halfOvertime"
                                                name="halfOvertime"
                                                label="Half Hour Overtime:"
                                                helperText={`(Minutes (hh:01 - hh:${departmentData.halfOvertime}))`}
                                                type="number"
                                                value={departmentData.halfOvertime}
                                                onChange={handleInputChange}
                                                disabled={departmentData.overtime == "no" ? true : false}
                                            />
                                            <TextField fullWidth variant="standard"
                                                id="fullOvertime"
                                                name="fullOvertime"
                                                label="Full Hour Overtime:"
                                                helperText={`(Minutes (hh:01 - hh:${departmentData.fullOvertime}))`}
                                                type="number"
                                                value={departmentData.fullOvertime}
                                                onChange={handleInputChange}
                                                disabled={departmentData.overtime == "no" ? true : false}
                                            /> */}
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal1}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={addDepartment}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Add"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>

                            <Box>
                                <Dialog
                                    open={modalIsOpen2}
                                    onClose={closeModal2}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Edit Department`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box display="flex" flexDirection="row" gap={2}>
                                                <TextField required autoFocus fullWidth variant="standard"
                                                    id="departmentName"
                                                    name="departmentName"
                                                    label="Department Name:"
                                                    type="text"
                                                    value={editDepartment.departmentName}
                                                    onChange={handledata}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="inTime"
                                                    name="inTime"
                                                    label="In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.inTime}
                                                    onChange={handledata}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="late"
                                                    name="late"
                                                    label="Late In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.late}
                                                    onChange={handledata}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="halfdayin"
                                                    name="halfdayin"
                                                    label="Half Day In Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.halfdayin}
                                                    onChange={handledata}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="outTime"
                                                    name="outTime"
                                                    label="Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.outTime}
                                                    onChange={handledata}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="early"
                                                    name="early"
                                                    label="Early Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.early}
                                                    onChange={handledata}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="halfdayout"
                                                    name="halfdayout"
                                                    label="Half Day Out Time:"
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={editDepartment.halfdayout}
                                                    onChange={handledata}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="deduction"
                                                    name="deduction"
                                                    label="Deduction:"
                                                    value={editDepartment.deduction}
                                                    onChange={handledata}
                                                    select
                                                >
                                                    <MenuItem value="yes">Yes</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                </TextField>
                                                <TextField fullWidth variant="standard"
                                                    id="halfDayOnLate"
                                                    name="halfDayOnLate"
                                                    label="1 Half Day Deduction on Late:"
                                                    type="number"
                                                    value={editDepartment.halfDayOnLate}
                                                    onChange={handledata}
                                                    disabled={editDepartment.deduction == "no" ? true : false}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="overtime"
                                                    name="overtime"
                                                    label="Overtime:"
                                                    value={editDepartment.overtime}
                                                    onChange={handledata}
                                                    select
                                                >
                                                    <MenuItem value="yes">Yes</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                </TextField>
                                                <TextField required fullWidth variant="standard"
                                                    id="workinghours"
                                                    name="workinghours"
                                                    label="Working Hours:"
                                                    value={editDepartment.workinghours}
                                                    onChange={handledata}
                                                    select
                                                >
                                                    <MenuItem value="4">4</MenuItem>
                                                    <MenuItem value="5">5</MenuItem>
                                                    <MenuItem value="6">6</MenuItem>
                                                    <MenuItem value="7">7</MenuItem>
                                                    <MenuItem value="8">8</MenuItem>
                                                    <MenuItem value="9">9</MenuItem>
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="11">11</MenuItem>
                                                    <MenuItem value="12">12</MenuItem>
                                                </TextField>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal2}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit"
                                            onClick={editDepartmentSubmit}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Submit"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            <Toaster />
                        </Box>
                        :
                        <div style={{ padding: 10 }}>View disabled</div>
                    :
                    ""
            }
        </>

    )
}

export default Department