import React from 'react'
import "../App.css"

export default function PrintableComponent({ arr }) {

    function changeTimeRegion(dateString) {

        const utcDate = new Date(dateString)

        const formatter = new Intl.DateTimeFormat('en-PK', {
            timeZone: 'Asia/Karachi',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        })

        let formValue = formatter.format(utcDate)

        const year = formValue.slice(6, 10)
        const month = formValue.slice(3, 5)
        const day = formValue.slice(0, 2)

        const formattedDate = `${day}-${month}-${year}`

        return formattedDate

    }

    return (
        <div id="printable-component" className="slip">
            <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                <tr>
                    <td
                        style={{ width: "545pt", border: "2px solid black" }}>
                        <p class="s1" style={{ textAlign: "center", textTransform: 'uppercase' }}>
                            {arr.companyName}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "545pt", border: "2px solid black" }}>
                        <p class="s2" style={{ textAlign: "center", textTransform: 'uppercase' }}>Pay slip for the month of {arr.month_name + " - " + arr.year} </p>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
                <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left" }}>Employee Name</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.employeename}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left" }}>Designation</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.designationName}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left" }}>Employee ID</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.employee_id}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "134pt", }}>
                            <p class="s2" style={{ paddingLeft: "2pt", textAlign: "left" }}>Days Worked</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.totalDaysWorked}</p>
                        </td>
                    </tr>
                </table>
                <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                    <tr >
                        <td style={{ width: "140pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left" }}>Date</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.salaryDate ? changeTimeRegion(arr.salaryDate) : ""}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "140pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left" }}>Payment Mode</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.paymentMode ? arr.paymentMode : "-"}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "140pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left" }}>Bank</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.employeebank ? arr.employeebank : "-"}</p>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ width: "140pt", }}>
                            <p class="s2" style={{ paddingLeft: "8pt", textAlign: "left" }}>Account No.</p>
                        </td>
                        <td style={{ width: "131pt", border: "2px solid black" }}>
                            <p class="s3" style={{ paddingLeft: "2pt", textAlign: "left" }}>{arr.bankaccount ? arr.bankaccount : "-"}</p>
                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                <tr>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center" }}>Earning</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center" }}>Amount</p>
                    </th>
                    <th
                        style={{ width: "4pt", border: "2px solid black", }}>
                        <p class="s2" style={{ textAlign: "center" }}><br /></p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center" }}>Deduction</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "center" }}>Amount</p>
                    </th>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Basic Pay</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.base_salary}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Advance</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.advance ? arr.advance : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Incentive Pay</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.incentive}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Loan</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.loan ? arr.loan : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Conveyance</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.conveyance}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Absent</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.absentDeductionTotal ? Math.round(arr.absentDeductionTotal) : 0}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Overtime</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{Math.round((arr.OT_hours * arr.OT_rate))}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Late Timing</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{Math.round(arr.lateDeductionTotal)}</p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Other Allowance</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.other_allowance}</p>
                    </td>
                    <td
                        style={{ width: "4pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}><br /></p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ textAlign: "left" }}>Half Day Deduction</p>
                    </td>
                    <td
                        style={{ width: "132pt", border: "1px solid black", }}>
                        <p class="s3" style={{ paddingRight: "2pt", textAlign: "right" }}>{Math.round(arr.halfDayDeductionTotal)}</p>
                    </td>
                </tr>
                <tr>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left" }}>Total Earning</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right" }}>{arr.base_salary + arr.incentive + arr.conveyance + arr.other_allowance + Math.round((arr.OT_hours * arr.OT_rate))}</p>
                    </th>
                    <th
                        style={{ width: "4pt", border: "2px solid black", }}>
                        <p class="s2" style={{ textAlign: "center" }}><br /></p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left" }}>Total Deduction</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right" }}>{
                            Math.round(arr.absentDeductionTotal)
                            + Math.round(arr.lateDeductionTotal) + Math.round(arr.halfDayDeductionTotal)}</p>
                    </th>
                </tr>
            </table>
            <br />
            <table style={{ borderCollapse: "collapse", }} cellspacing="0">
                <tr>
                    <th style={{ width: "276pt", border: "0px solid black" }}></th>
                    <th
                        style={{ width: "131pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ textAlign: "left" }}>Net Pay</p>
                    </th>
                    <th
                        style={{ width: "132pt", border: "2px solid black", background: "#D3D3D3" }}>
                        <p class="s2" style={{ paddingRight: "2pt", textAlign: "right" }} a>
                            {/* {Math.round(arr.grossSalary) + Math.round((arr.OT_hours * arr.OT_rate))} */}
                            {((Math.round((arr.OT_hours ? arr.OT_hours : 0) * Math.round(arr.OT_rate)) + Math.round(arr.totalSalary)) + (arr.conveyance + arr.incentive + arr.other_allowance)) - (arr.advance ? arr.advance : 0 + arr.loan ? arr.loan : 0 + arr.tax ? arr.tax : 0)}
                        </p>
                    </th>
                </tr>
            </table>
            <br />
            <br />
            <br />
            <br />
            <table>
                <tr>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                    <td style={{ width: "260pt", borderTop: "2px solid black" }}>
                        <p style={{ textAlign: "Center" }}>Authorised Signature</p>
                    </td>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                    <td style={{ width: "260pt", borderTop: "2px solid black" }}>
                        <p style={{ textAlign: "Center" }}>Employee Signature</p>
                    </td>
                    <td style={{ width: "4pt", borderTop: "0px solid black" }}></td>
                </tr>
            </table>
        </div>

    )
}