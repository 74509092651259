import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { useAuth } from '../AuthContext'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240
const navItems = [
    { name: "Dashboard", path: "/dashboard" },
]

function DefaultNavBar({ window }) {

    const { logout } = useAuth()
    const navigate = useNavigate()

    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Simple Payroll
            </Typography>
            <Divider />
            {/* <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(item.path)}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </Box>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ bgcolor: "#0d2846" }}>
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 0.1, display: { xs: 'none', sm: 'block' }, fontSize: "1.5rem" }}
                    >
                        Simple Payroll
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        {/* {navItems.map((item) => (
                            <Button key={item.name} sx={{
                                textTransform: 'capitalize',
                                fontSize: { sm: "1rem" },
                                // lineHeight: { xs: 1, sm: 1 },
                                bgcolor: "#0d2846", color: 'white',
                                marginRight: 1,
                                '&:hover': {
                                    bgcolor: 'lightblue',
                                    color: 'black',
                                    cursor: 'pointer',
                                },
                            }} onClick={() => navigate(item.path)}>
                                {item.name}
                            </Button>

                        ))} */}
                    </Box>
                    {/* <Box sx={{ display: { xs: 'none', sm: 'block' }, justifyContent: 'flex-end' }}>
                        <Button sx={{
                            textTransform: 'capitalize',
                            bgcolor: "#0d2846", color: 'white',
                            border: "1px solid white",
                            '&:hover': {
                                bgcolor: 'lightblue',
                                color: 'black',
                                cursor: 'pointer',
                                border: "none"
                            },
                        }} onClick={() => logout()}>
                            Log out
                        </Button>
                    </Box> */}
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <div style={{ marginTop: 70 }}>

            </div>
        </Box>
    </>
}

DefaultNavBar.propTypes = {
    window: PropTypes.func,
}

export default DefaultNavBar