import * as React from 'react'
import PropTypes from 'prop-types'
import { Box, Tabs, Tab, Typography, Grid, useMediaQuery, useTheme } from '@mui/material'


function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

export default function VerticalTabs({ jsxCode, jsxUpload, jsxSummary, jsxSummaryName, jsxAttendanceSummary, jsxAttendanceSummaryName, jsxSalaryList, jsxSalaryListName, jsxPassword, jsxPasswordName, orien }) {
    const [value, setValue] = React.useState(0)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', margin: orien ? 0 : "", padding: orien ? 0 : "" }}
        >
            <Grid container>
                <Grid item sx={{ xs: 12, sm: 2, md: 1, width: { xs: "100%", sm: "100%", md: "auto" } }} container justifyContent="center">
                    <Box sx={{ maxWidth: { xs: 350, sm: 480 } }}>
                        <Tabs
                            orientation={isMobile ? 'horizontal' : orien ? "horizontal" : 'vertical'}
                            variant={isMobile ? "scrollable" : ""}
                            value={value}
                            onChange={handleChange}
                            scrollButtons={false}
                            sx={{ borderRight: isMobile ? 0 : orien ? 0 : 1, borderColor: isMobile ? '' : "divider" }}
                        >
                            <Tab style={{ fontSize: isMobile ? 12 : 14 }} label={jsxUpload} {...a11yProps(0)} />
                            <Tab style={{ fontSize: isMobile ? 12 : 14 }} label={jsxSummaryName} {...a11yProps(1)} />
                            <Tab style={{ display: jsxAttendanceSummary ? "" : "none", fontSize: isMobile ? 12 : 14 }} label={jsxAttendanceSummaryName} {...a11yProps(2)} />
                            <Tab style={{ display: jsxSalaryList ? "" : "none", fontSize: isMobile ? 12 : 14 }} label={jsxSalaryListName} {...a11yProps(3)} />
                            <Tab style={{ display: jsxPassword ? "" : "none", fontSize: isMobile ? 12 : 14 }} label={jsxPasswordName} {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                </Grid>

                <Grid item sx={12} sm={10} md={11}>
                    <TabPanel value={value} index={0}>
                        {jsxCode}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {jsxSummary}
                    </TabPanel>
                    <TabPanel style={{ display: jsxAttendanceSummary ? "" : "none" }} value={value} index={2}>
                        {jsxAttendanceSummary}
                    </TabPanel>
                    <TabPanel style={{ display: jsxSalaryList ? "" : "none" }} value={value} index={3}>
                        {jsxSalaryList}
                    </TabPanel>
                    <TabPanel style={{ display: jsxPassword ? "" : "none" }} value={value} index={4}>
                        {jsxPassword}
                    </TabPanel>
                </Grid>
            </Grid>


        </Box>
    )
}