import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthContext'

const ProtectedRouteEmployee = () => {
  
  const { isAuthenticatedEmployee } = useAuth()

  if (!isAuthenticatedEmployee) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

export {
    ProtectedRouteEmployee
} 