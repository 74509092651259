import * as React from 'react'
import { useState } from 'react'
import { Alert, Avatar, Button, TextField, FormControlLabel, Checkbox, Link, Box, Typography } from '@mui/material'
import { avatarImage, BackgroundImage } from "./Img/Img"
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

const userNameRegex = /^(?![_\.])[a-zA-Z0-9_]{6,18}(?<![_\.])$/
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const PasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/

function Signin() {

    const navigate = useNavigate()

    const [userName, setUserName] = useState("")
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [retypePassword, setRetypePassword] = useState("")
    const [userNameErrorAlertOpen, setUserNameErrorAlertOpen] = useState(false)
    const [EmailErrorAlertOpen, setEmailErrorAlertOpen] = useState(false)
    const [passwordErrorAlertOpen, setPasswordErrorAlertOpen] = useState(false)
    const [retypePasswordErrorAlertOpen, setretypePasswordErrorAlertOpen] = useState(false)
    const [OTPErrorAlertOpen, setOTPErrorAlertOpen] = useState(false)
    const [promo, setPromo] = useState(1)
    const [message, setMessage] = useState(null)
    const [isAuth, setIsAuth] = useState()
    const [flag, setFlag] = useState(false)
    const [serverOTP, setServerOTP] = useState("")
    const [checkOTP, setCheckOTP] = useState("")
    const [buttonMessage, setButtonMessage] = useState('')

    function handleUserNameBlur() {
        if (!userName.match(userNameRegex)) {
            setUserNameErrorAlertOpen(true)
            return
        }
        else {
            setUserNameErrorAlertOpen(false)
        }
    }

    function handleEmailNameBlur() {
        if (!Email.match(emailRegex)) {
            setEmailErrorAlertOpen(true)
            return
        }
        else {
            setEmailErrorAlertOpen(false)
        }
    }

    function handlePasswordBlur() {
        if (!Password.match(PasswordRegex)) {
            setPasswordErrorAlertOpen(true)
            return
        }
        else {
            setPasswordErrorAlertOpen(false)
        }
    }

    function handleRetypePasswordBlur() {
        if (!retypePassword.match(Password)) {
            setretypePasswordErrorAlertOpen(true)
            return
        }
        else {
            setretypePasswordErrorAlertOpen(false)
        }
    }

    function handleOTP() {
        if (!(checkOTP == serverOTP)) {
            setOTPErrorAlertOpen(true)
        }
        else {
            setOTPErrorAlertOpen(false)
        }
    }

    const handleLogin = async () => {

        if (!userNameErrorAlertOpen && !userNameErrorAlertOpen && !passwordErrorAlertOpen && !retypePasswordErrorAlertOpen && !OTPErrorAlertOpen) {
            try {
                setButtonMessage('Registering')
                const response = await axios.post('/api/register', { name: userName, email: Email, password: Password, promo: promo })
                if (response.data.success) {
                    setIsAuth(true)
                    setMessage(response.data.message)
                    setUserName("")
                    setEmail("")
                    setPassword("")
                    setRetypePassword("")
                }
                else {
                    setButtonMessage('')
                    setIsAuth(false)
                    if (response.data.error.sqlMessage.includes("Duplicate entry")) {
                        setMessage("Email already exists")
                    }
                    else {
                        setMessage("Something went wrong, please try again later")
                    }
                }
            }
            catch (error) {
                setButtonMessage('')
                setIsAuth(false)
                setMessage("Something went wrong. Please try again later")
            }
        }
    }

    function route() {
        navigate('/')
    }

    function abc(e) {
        if (e) {
            setPromo(1)
        }
        else {
            setPromo(0)
        }
    }

    async function checkEmailAvailable() {
        if (Email.match(emailRegex) && userName.match(userNameRegex)) {
            try {
                setButtonMessage("Checking")
                const response = await axios.post('/api/checkEmailAvailable', { Email: Email, userName: userName })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    setFlag(response.data.success)
                    setServerOTP(response.data.OTP)
                }
                else {
                    setButtonMessage('')
                    toast.error(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                }
            }
            catch (error) {
                setButtonMessage('')
                console.log("Something went wrong. Please try again later")
            }
        }
        else {
            toast.error("Incomplete Info. Username/Email", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                backgroundImage: { sm: `url(${BackgroundImage})` },
                width: "100%",
                height: "100vh",
                backgroundSize: "100% 100vh",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    gap: { xs: 0, sm: 0, md: 20, lg: 30 },
                    flexDirection: 'row',
                    alignItems: 'top',
                    justifyItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        margin: 2,
                        display: { xs: "none", sm: "none", md: "flex" },
                        flexDirection: 'column',
                        alignItems: 'left',
                        maxWidth: { xs: "fullWidth", sm: "60%", md: "50%", lg: "45%" },
                    }}
                >
                    <Typography variant="h3" style={{ color: "white" }}>PAYROLL</Typography>
                    <Typography variant="h3" style={{ color: "white" }}>MANAGEMENT SYSTEM</Typography>
                </Box>
                <Box
                    sx={{
                        margin: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'center',
                        maxWidth: { xs: "fullWidth", sm: "60%", md: "50%", lg: "35%" },
                        border: { xs: 1, sm: "" },
                        borderRadius: '8px',
                        borderColor: { xs: "lightgrey", sm: "" },
                        backgroundColor: "white",
                    }}
                >
                    <Avatar alt="Simple Payroll" src={avatarImage} sx={{ width: 56, height: 56, mt: 3, borderRadius: 0 }} />
                    <Typography variant="h4">Sign up</Typography>
                    <Box sx={{ mt: 3, margin: 2 }}>
                        <TextField
                            required
                            fullWidth
                            label="User Name"
                            type="text"
                            id="userName"
                            onChange={(e) => setUserName(e.target.value)}
                            onBlur={handleUserNameBlur}
                            value={userName}
                        />
                        {userNameErrorAlertOpen && (
                            <Alert severity="error">
                                Username must not start or end with '_' and can only contain letters, numbers, and underscores
                            </Alert>
                        )}
                        <TextField
                            sx={{ mt: 2 }}
                            required
                            fullWidth
                            label="Email"
                            type="email"
                            id="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleEmailNameBlur}
                            value={Email}
                        />
                        {EmailErrorAlertOpen && (
                            <Alert severity="error">
                                Invalid email
                            </Alert>
                        )}
                        {!flag &&
                            <>
                                <Box sx={{ display: "flex", flexDirection: "column", alignContent: "flex-end" }}>
                                    <Button
                                        type="submit"

                                        variant="contained"
                                        sx={{ width: "25%", mt: 3, mb: 2 }}
                                        onClick={() => checkEmailAvailable()}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        {buttonMessage !== '' ? buttonMessage : "next"}
                                    </Button>
                                </Box>
                                <Link
                                    sx={{ ml: 0 }}
                                    variant="body2"
                                    style={{
                                        textDecoration: "none",
                                        cursor: "pointer"
                                    }} >
                                    <span
                                        onClick={route}
                                    >Already have an account? Sign In
                                    </span>
                                </Link>
                            </>
                        }
                        {flag &&
                            <Box>
                                <TextField
                                    sx={{ mt: 2 }}
                                    required
                                    fullWidth
                                    label="OTP"
                                    type="text"
                                    id="OTP"
                                    onChange={(e) => setCheckOTP(e.target.value)}
                                    onBlur={handleOTP}
                                    value={checkOTP}
                                />
                                {OTPErrorAlertOpen && (
                                    <Alert severity="error">
                                        Invalid OTP
                                    </Alert>
                                )}
                                <TextField
                                    sx={{ mt: 2 }}
                                    required
                                    fullWidth
                                    label="Password"
                                    type="Password"
                                    id="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    onBlur={handlePasswordBlur}
                                    value={Password}
                                />
                                {passwordErrorAlertOpen && (
                                    <Alert severity="error">
                                        Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one symbol and one number
                                    </Alert>
                                )}
                                <TextField
                                    sx={{ mt: 2 }}
                                    required
                                    fullWidth
                                    label="Retype Password"
                                    type="Password"
                                    id="retypePassword"
                                    onChange={(e) => setRetypePassword(e.target.value)}
                                    onBlur={handleRetypePasswordBlur}
                                    value={retypePassword}
                                />
                                {retypePasswordErrorAlertOpen && (
                                    <Alert severity="error">
                                        Password doesn't match
                                    </Alert>
                                )}
                                <FormControlLabel
                                    checked={promo == 1 ? true : false}
                                    onChange={(e) => abc(e.target.checked)}
                                    sx={{ marginTop: 2 }}
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label={
                                        <Typography variant="body1" fontSize="0.9rem">
                                            I want to receive inspiration, marketing promotions and updates via email.
                                        </Typography>
                                    }
                                />
                                <Box sx={{ display: "flex", flexDirection: "column", alignContent: "flex-end" }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ width: "50%", mt: 3, mb: 2 }}
                                        onClick={() => handleLogin()}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        {buttonMessage !== '' ? buttonMessage : "sign up"}
                                    </Button>
                                    <Link
                                        sx={{ ml: 0 }}
                                        variant="body2"
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer"
                                        }} >
                                        <span
                                            onClick={route}
                                        >Already have an account? Sign In
                                        </span>
                                    </Link>
                                </Box>
                                {message && (
                                    <Alert severity={isAuth ? "success" : "error"}>
                                        {message} {isAuth ? <span>Please click <span onClick={route} style={{ textDecoration: "underline", cursor: "pointer" }}>here</span> to login</span> : ""}
                                    </Alert>
                                )}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Toaster />
        </Box>
        // </Box >
    )
}

export default Signin