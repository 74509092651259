import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthContext'

const ProtectedRoute = () => {

  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

export {
  ProtectedRoute
}