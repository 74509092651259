import * as React from 'react'
import { useState } from 'react'
import { Button, TextField, Box, Typography } from '@mui/material'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import toast, { Toaster } from 'react-hot-toast'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const ForgottenPassword = () => {

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [flag, setFlag] = useState(false)
    const [newCredentials, setNewCredentials] = useState({ OTP: "", newPassword: "", newConfirmPassword: "" })
    const [serverOTP, setServerOTP] = useState("")
    const [UserID, setUserID] = useState("")
    const [type, setType] = useState("")
    const [buttonMessage, setButtonMessage] = useState('')

    function back() {
        navigate('/')
    }

    async function searchEmail() {
        if (email.match(emailRegex) && type !== "") {
            try {
                setButtonMessage("Searching")
                const response = await axios.post('/api/searchEmail', { Email: email, Type: type })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    setFlag(response.data.success)
                    setServerOTP(response.data.OTP)
                    setUserID(response.data.UserID)
                }
                else {
                    setButtonMessage('')
                    toast.error(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                }
            }
            catch (error) {
                setButtonMessage('')
                console.log("Something went wrong. Please try again later")
            }
        }
        else {
            toast.error("Please provide complete information", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }
    }

    async function changePassword() {

        var regex_pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{};:,<.>]).{8,}$/

        if (!(newCredentials.OTP == serverOTP)) {
            toast.error("Invalid OTP", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }

        if (!newCredentials.newPassword.match(regex_pattern)) {
            toast.error("Password should be atleast 8 digits, including 1 uppercase and a special character", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }

        if (!(newCredentials.newPassword == newCredentials.newConfirmPassword)) {
            toast.error("Passwords do not match", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }

        if (newCredentials.OTP == serverOTP && newCredentials.newPassword.match(regex_pattern) && newCredentials.newPassword == newCredentials.newConfirmPassword) {
            try {
                setButtonMessage('submitting')
                const response = await axios.post('/api/updatePassword', {
                    id: UserID,
                    password: newCredentials.newPassword,
                    type: type
                })
                if (response.data.success) {
                    setButtonMessage('')
                    setNewCredentials({ OTP: "", newPassword: "", newConfirmPassword: "" })
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    setTimeout(() => {
                        back()
                    }, 3000)
                }
                else {
                    setButtonMessage('')
                    console.error('Failed to update password: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error updating password: ', error)
            }
        }
    }

    // <div style={{ width: "100%" }}>
    return <Box
        sx={{
            display: 'flex',
            // border: "1px solid blue",
            // flexDirection: 'row',
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            width: "100%",
            height: { xs: "80vh", sm: "90vh" },
            backgroundSize: "100% 100vh",
        }}>
        <Box
            sx={{
                display: 'flex',
                // flexDirection: 'row',
                // border: "1px solid red",
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                maxWidth: { xs: "95%", sm: "90%", md: "auto" },
            }}
        >
            <Card variant="outlined">
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="h5" component="div">
                            Find your account
                        </Typography>
                    </Stack>
                    <Typography>
                        Please enter your email address to search for your account.
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <TextField
                        required
                        fullWidth
                        label="Email"
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <br />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Account Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Company" />
                            <FormControlLabel value="2" control={<Radio />} label="Employee" />
                        </RadioGroup>
                    </FormControl>
                </Box>
                {flag &&
                    <Box sx={{ p: 2 }}>
                        <TextField
                            required
                            fullWidth
                            label="OTP"
                            type="text"
                            id="OTP"
                            name="OTP"
                            onChange={(e) => setNewCredentials({ ...newCredentials, [e.target.name]: e.target.value })}
                            sx={{ marginBottom: 1 }}
                        />
                        <TextField
                            required
                            fullWidth
                            label="New Password"
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            onChange={(e) => setNewCredentials({ ...newCredentials, [e.target.name]: e.target.value })}
                            sx={{ marginBottom: 1 }}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Confirm New Password"
                            type="password"
                            id="newConfirmPassword"
                            name="newConfirmPassword"
                            onChange={(e) => setNewCredentials({ ...newCredentials, [e.target.name]: e.target.value })}
                            sx={{ marginBottom: 1 }}
                        />
                    </Box>
                }
                <Divider />
                <Box sx={{ pl: 2, pb: 2 }}>
                    <Button
                        onClick={() => back()}
                        style={{ marginRight: 5 }}
                        variant='outlined'
                    >
                        Back
                    </Button>
                    {!flag && <Button
                        variant='contained'
                        onClick={() => searchEmail()}
                        style={{ marginRight: 5 }}
                        disabled={buttonMessage !== '' ? true : false}
                    >
                        {buttonMessage !== '' ? buttonMessage : "Search"}
                    </Button>
                    }
                    {flag &&
                        <Button
                            onClick={() => changePassword()}
                            variant='contained'
                            color="success"
                            disabled={buttonMessage !== '' ? true : false}
                        >
                            {buttonMessage !== '' ? buttonMessage : "confirm"}
                        </Button>
                    }
                </Box>
            </Card>
        </Box>
    </Box>
    {/* </div> */ }
    <Toaster />
    // </div>
}

export default ForgottenPassword