import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthContext'

const ProtectedRouteAdmin = () => {

  const { isAuthenticatedAdmin } = useAuth()

  if (!isAuthenticatedAdmin) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

export {
    ProtectedRouteAdmin
} 